<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      label-width="120px"
    >
      <el-form-item label="部门" prop="departmentId">
        <el-select
          v-model="dataForm.departmentId"
          placeholder="请选择部门"
          style="width: 100%"
          clearable
          filterable
          @change="selectDepartment"
        >
          <el-option
            v-for="item in departmentList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="负责人" prop="chargeId">
        <el-select
          v-model="dataForm.chargeId"
          clearable
          filterable
          style="width: 100%"
          placeholder="请选择负责人"
          @change="chargeIdChange"
        >
          <el-option
            v-for="item in allEmployeeList"
            :key="item.id"
            :label="item.name + '(' + item.departmentName + ')'"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="安全职责" prop="aqzz">
        <el-input
          v-model="dataForm.aqzz"
          placeholder="安全职责"
          type="textarea"
          rows="6"
        ></el-input>
      </el-form-item>
      <el-form-item label="管辖范围" prop="gxfw">
        <el-input
          v-model="dataForm.gxfw"
          placeholder="管辖范围"
          type="textarea"
          rows="6"
        ></el-input>
      </el-form-item>
      <el-form-item label="生效时间" prop="effectiveDate">
        <el-date-picker
          style="width: 100%"
          value-format="yyyy-MM-dd HH:mm:ss"
          v-model="dataForm.effectiveDate"
          type="date"
          placeholder="选择生效时间"
        >
        </el-date-picker>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      departmentList: [], // 部门列表
      allEmployeeList: [],
      visible: false,
      dataForm: {
        id: 0,
        departmentId: "",
        departmentName: "",
        chargeId: "",
        chargeName: "",
        aqzz: "",
        gxfw: "",
        effectiveDate: "",
        createDate: "",
        updateDate: "",
        isDeleted: "",
      },
      dataRule: {
        departmentId: [
          { required: true, message: "部门不能为空", trigger: "blur" },
        ],
        chargeId: [
          { required: true, message: "负责人不能为空", trigger: "blur" },
        ],
        aqzz: [
          { required: true, message: "安全职责不能为空", trigger: "blur" },
        ],
        gxfw: [
          { required: true, message: "管辖范围不能为空", trigger: "blur" },
        ],
        effectiveDate: [
          { required: true, message: "生效时间不能为空", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    // 获取所有员工
    getAllEmployee() {
      this.$http.employee.list({ limit: 99999, page: 1 }).then((res) => {
        if (res && res.code == 0) {
          this.allEmployeeList = res.data.list;
        }
      });
    },
    // 选择的负责人发生变化
    chargeIdChange(e) {
      this.dataForm.chargeName = "";
      if (e) {
        // 获取当前选择的员工名字
        let result = this.allEmployeeList.filter((ele) => {
          return ele.id == e;
        });
        if (result.length > 0) {
          this.dataForm.chargeName = result[0].name;
        }
      }
    },
    // 选择部门
    selectDepartment(value) {
      this.departmentList.forEach((item) => {
        if (item.id == value) {
          this.dataForm.departmentName = item.name;
          if (item.employee) {
            this.dataForm.chargeId = item.employee.id;
            this.dataForm.chargeName = item.employee.name;
          }
        }
      });
    },
    // 获取部门列表
    getDepartmentList() {
      this.$http.department.list({ limit: 9999, page: 1 }).then((res) => {
        if (res && res.code == 0) {
          this.departmentList = res.data.list;
        }
      });
    },
    init(id) {
      this.getAllEmployee();
      this.getDepartmentList();
      this.dataForm.id = id || 0;
      this.visible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
        if (this.dataForm.id) {
          this.$http.griddepartment
            .info({ id: this.dataForm.id })
            .then((data) => {
              if (data && data.code === 0) {
                this.dataForm = data.data;
              }
            });
        }
      });
    },
    dataFormSubmit() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          let requestDate = this.dataForm;

          if (this.dataForm.id) {
            // 修改
            this.$http.griddepartment.update(requestDate).then((data) => {
              if (data && data.code === 0) {
                this.$message({
                  message: "修改操作成功",
                  type: "success",
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
                    this.$emit("refreshDataList");
                  },
                });
              } else {
                this.$message.error(data.msg);
              }
            });
          } else {
            // 新增
            requestDate.id = "";
            this.$http.griddepartment.save(requestDate).then((data) => {
              if (data && data.code === 0) {
                this.$message({
                  message: "添加成功",
                  type: "success",
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
                    this.$emit("refreshDataList");
                  },
                });
              } else {
                this.$message.error(data.msg);
              }
            });
          }
        }
      });
    },
  },
};
</script>
